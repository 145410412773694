import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Image from 'next/image';
import Link from 'next/link';
import TextSectionStyles from './TextSection.styled';
import Button from '@components/Shared/Buttons/Button';
import Markdown from '@components/Shared/Markdown/Markdown';
import {
  extractColors,
  formatHttpsUrl,
  isUrlSameDomain,
  auditMarkdownLinks,
} from '@utils/utils';
import {
  getContentTextAlignment,
  getContentFlexAlignment,
} from '@utils/contentful';
import { slugify } from '@utils/studioUtils';
import ImageModalVideo from './ImageModalVideo/ImageModalVideo';
import { getImageAlignment } from '@utils/ctaUtils';
import useWindowResize from 'pubweb-smokey/dist/hooks/useWindowResize';
import {
  tablet_container_maxwidth,
  sm_desktop_breakpoint,
} from 'pubweb-smokey/dist/components/GridSystem/_vars_widths.js';

const TextSection = ({ section }) => {
  const [imgQuery, setImgQuery] = useState('?w=800&fm=webp');

  useEffect(() => {
    auditMarkdownLinks('text-section-container');
  }, []);

  // Could possibly use isMobile() and give it a width value, but it did not seem to reevaluate unless you refreshed the browser.
  useWindowResize(() => {
    if (typeof window !== 'undefined') {
      if (window.innerWidth > tablet_container_maxwidth) {
        // Selected tablet container maxwidth because some of the images were displayed wider at narrower screen widths.
        setImgQuery('?w=800&fm=webp');
      } else {
        setImgQuery('?w=506&fm=webp');
      }
    }
  }, [tablet_container_maxwidth, sm_desktop_breakpoint]);

  return (
    <TextSectionStyles
      style={{
        backgroundColor: extractColors(section?.sectionBackgroundColor),
      }}
    >
      {section?.video && (
        <div
          id={slugify(section?.sectionHeader)}
          className="text-section-container"
        >
          <div
            className={`text-section-embedded-content ${getImageAlignment(
              section?.imageLocation
            )}`}
          >
            <div
              className="text-section-left"
              style={{
                textAlign: getContentTextAlignment(section?.alignText),
                color: extractColors(section?.sectionContentTextColor),
              }}
            >
              {section?.sectionLabel && (
                <h5
                  className="text-section-label"
                  style={{
                    color: extractColors(section?.labelTextColor),
                  }}
                >
                  {section?.sectionLabel}
                </h5>
              )}
              <h2
                className="text-section-heading"
                style={{
                  color: extractColors(section?.headerTextColor),
                }}
              >
                {section?.sectionHeader}
              </h2>
              <Markdown markdown={section?.sectionContent} />
              {section?.disclaimer && (
                <div className="disclaimer">
                  <Markdown markdown={section?.disclaimer} />
                </div>
              )}
            </div>
            <div
              className={`text-section-right ${getImageAlignment(
                section?.imageLocation
              )}`}
            >
              <ImageModalVideo
                image={section?.video.embeddedImage?.file?.url}
                wistiaID={section?.video.embeddedContent}
                altText={section?.video?.embeddedImage?.description}
              />
            </div>
          </div>
        </div>
      )}

      {!section?.video && section?.featuredImage && (
        <div
          id={slugify(section?.sectionHeader)}
          className="text-section-container"
        >
          <div
            className={`text-section-embedded-content ${getImageAlignment(
              section?.imageLocation
            )}`}
          >
            <div
              className="text-section-left"
              style={{
                textAlign: getContentTextAlignment(section?.alignText),
                color: extractColors(section?.sectionContentTextColor),
              }}
            >
              {section?.sectionLabel && (
                <h5
                  className="text-section-label"
                  style={{
                    color: extractColors(section?.labelTextColor),
                  }}
                >
                  {section?.sectionLabel}
                </h5>
              )}
              <h2
                className="text-section-heading"
                style={{
                  color: extractColors(section?.headerTextColor),
                }}
              >
                {section?.sectionHeader}
              </h2>
              <Markdown markdown={section?.sectionContent} />
              {section?.disclaimer && (
                <div className="disclaimer">
                  <Markdown markdown={section?.disclaimer} />
                </div>
              )}
            </div>
            <div
              className={`text-section-right ${getImageAlignment(
                section?.imageLocation
              )}`}
            >
              <div className="text-section-image">
                <Image
                  src={formatHttpsUrl(
                    `${section?.featuredImage?.file?.url}${imgQuery}`
                  )}
                  alt={section?.featuredImage?.description}
                  width={622}
                  height={467}
                />
              </div>
            </div>
          </div>
        </div>
      )}

      {!section?.featuredImage && !section?.video ? (
        <div
          className="text-section-container"
          style={{
            textAlign: getContentTextAlignment(section?.alignText),
            color: extractColors(section?.sectionContentTextColor),
          }}
        >
          {section?.sectionHeader && (
            <h2
              className="text-section-heading"
              style={{
                color: extractColors(section?.headerTextColor),
              }}
            >
              {section?.sectionHeader}
            </h2>
          )}
          <Markdown markdown={section?.sectionContent} />
          {section?.sectionLinks && section?.sectionLinks.length > 0 && (
            <ul
              className="text-section-links"
              style={{
                display:
                  section?.sectionLinks[0].displayLinkAs === 'Button'
                    ? 'flex'
                    : 'grid',
                justifyContent: getContentFlexAlignment(section?.alignText),
              }}
            >
              {section?.sectionLinks.map((sectionLink, i) => {
                return (
                  <li key={`link-${slugify(sectionLink.linkText)}-${i + 1}`}>
                    {/* eslint-disable-next-line react/jsx-no-target-blank */}
                    {sectionLink.displayLinkAs === 'Button' ? (
                      <Button
                        navigateUrl={sectionLink.link}
                        target={
                          !isUrlSameDomain(sectionLink.link)
                            ? '_blank'
                            : '_self'
                        }
                        automationId={`link-${slugify(sectionLink.linkText)}-${i + 1}`}
                        style={{
                          backgroundColor: extractColors(
                            sectionLink.buttonAndtextColors
                          )?.[0],
                          color: extractColors(
                            sectionLink.buttonAndtextColors
                          )?.[1],
                        }}
                      >
                        {sectionLink.linkText}
                      </Button>
                    ) : (
                      <Link
                        href={sectionLink.link}
                        target={
                          !isUrlSameDomain(sectionLink.link)
                            ? '_blank'
                            : '_self'
                        }
                        style={{
                          color: extractColors(
                            sectionLink.buttonAndtextColors
                          )?.[1],
                        }}
                        rel={
                          !isUrlSameDomain(sectionLink.link)
                            ? 'noreferrer'
                            : null
                        }
                      >
                        {sectionLink.linkText}
                      </Link>
                    )}
                  </li>
                );
              })}
            </ul>
          )}
        </div>
      ) : null}
    </TextSectionStyles>
  );
};

export default TextSection;

TextSection.propTypes = {
  section: PropTypes.shape({
    alignText: PropTypes.string,
    sectionLabel: PropTypes.string,
    labelTextColor: PropTypes.string,
    sectionHeader: PropTypes.string,
    headerTextColor: PropTypes.string,
    featuredImage: PropTypes.shape({
      description: PropTypes.string,
      file: PropTypes.shape({
        url: PropTypes.string,
      }),
    }),
    imageLocation: PropTypes.string,
    video: PropTypes.shape({
      embeddedContent: PropTypes.string,
      embeddedImage: PropTypes.shape({
        description: PropTypes.string,
        file: PropTypes.shape({
          url: PropTypes.string,
        }),
      }),
    }),
    sectionContent: PropTypes.string,
    sectionContentTextColor: PropTypes.string,
    sectionLinks: PropTypes.arrayOf(
      PropTypes.shape({
        linkText: PropTypes.string,
        link: PropTypes.string,
        displayLinkAs: PropTypes.string,
        buttonAndtextColors: PropTypes.string,
      })
    ),
    sectionLink: PropTypes.string,
    disclaimer: PropTypes.string,
    sectionBackgroundColor: PropTypes.string,
  }),
};
