import React from 'react';
import PageTransition from '@components/Shared/PageTransition/PageTransition';
import { extractColors } from '@utils/utils';
import { PageTransitionStyles, MiniNavStyles } from './PageTransition.styled';

interface WrapperProps {
  hasNavLinks?: boolean;
  titleBackgroundColor?: string;
  backgroundColor?: string;
  textColor?: string;
  overviewTextColor?: string;
  children: React.ReactNode;
}

interface PageTransitionProps {
  titleBackgroundColor?: string;
  backgroundColor?: string;
  textColor?: string;
  overviewTextColor?: string;
  navLinks?: Array<{
    transitionTitle: string;
    anchorLink: string;
  }>;
}

const Wrapper = ({
  hasNavLinks,
  titleBackgroundColor = '',
  backgroundColor = '',
  textColor = '',
  overviewTextColor = '',
  children,
}: WrapperProps) => {
  return (
    <>
      {hasNavLinks ? (
        <MiniNavStyles
          $backgroundColor={extractColors(backgroundColor)}
          $textColor={extractColors(textColor)}
        >
          <div className="mini-nav-container">{children}</div>
        </MiniNavStyles>
      ) : (
        <PageTransitionStyles
          style={{ backgroundColor: extractColors(backgroundColor) }}
          $backgroundColor={extractColors(titleBackgroundColor)}
          $textColor={extractColors(textColor)}
          $overviewTextColor={extractColors(overviewTextColor)}
        >
          {children}
        </PageTransitionStyles>
      )}
    </>
  );
};

const PageTransitionContainer = (props: PageTransitionProps) => {
  return (
    <Wrapper
      hasNavLinks={Boolean(props.navLinks?.length)}
      titleBackgroundColor={props.titleBackgroundColor}
      backgroundColor={props.backgroundColor}
      textColor={props.textColor}
      overviewTextColor={props.overviewTextColor}
    >
      <PageTransition {...props} />
    </Wrapper>
  );
};

export default PageTransitionContainer;
